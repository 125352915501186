/*
 * Copyright © 2023 Jean-Baptiste Sanchez
 *
 * This file is part of Momoro Portfolio 2023.
 *
 * For more information, the source code is available at:
 * https://github.com/jeanbapt-sanchez/momoro-portfolio-2023
 *
 * Momoro Portfolio 2023 is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

.project-details {
  height: 100vh;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 100% 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}

.cover {
  display: grid;
  grid-template-columns: 65% 35%;
  height: 100%;
  overflow-y: auto;
  /* padding: 240px 100px; */
}

.cover__content {
  padding: 150px 40px;
}

.cover__title {
  margin: 0px 0px 10px 0px;
  font-size: 95px;
  font-family: 'Dream Avenue';
}

.cover__description {
  margin: 0px 0px 0px 90px;
}

.cover__description > h1 {
  font-size: 20px;
}

.cover__description > p {
  font-size: 14px;
}

.cover__photo {
  background-color: var(--profile-background-color);
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.exhibition {
  display: flex;
  gap: 50px;
  margin-left: 50px;

  align-items: center;
}

/* TODO: Media queries for page project and center */
